
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap.scss";

@import "bower_components/fontawesome/scss/font-awesome.scss";

@import "./animate.scss";

@import "node_modules/spinkit/scss/spinkit.scss";

@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/slick-carousel/slick/slick-theme.scss";

@import "node_modules/sweetalert/dev/sweetalert.scss";

$pswp__assets-path: '/images/photoswipe/';
@import "node_modules/photoswipe/src/css/main.scss";
@import "node_modules/photoswipe/src/css/default-skin/default-skin.scss";

@import "./cart-system";

.text-left-imp {
  text-align: left!important;
}

.book-btn {
  margin-bottom: 20px;
}

.gallery-image {
  cursor: zoom-in;
}

.pswp__caption__center {
  text-align: center;
}

.pswp__instructions {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  display: none;
}

.pswp__item {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.pswp__item:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.footer-logos {
  padding: 10px 0;
  margin: 20px 0;
  text-align: center;
  border: solid 1px #ddd;
  border-radius: 5px;
}

.footer-logos img {
  margin: 5px 0;
  width: 75%;
}